<template>
  <section class="m-index m-scroll" ref="scroll" @scroll="handleScroll($event)">
    <!-- 搜索头部 -->
    <index-search :badge="badge"/>
    <div v-if="show" class="fade_gift" :class="shows ? 'trans' : ''">
      <img src="@/assets/index/goods.png" alt="" style="width:100%;height:100%">
    </div>
    <div class="m-index-header">
      <!-- 模块展示 -->
      <index-grid :data="sectionEntry" />
      <!-- 轮播 -->
      <van-swipe :autoplay="3000" class="m-swipe" :stop-propagation="false">
        <van-swipe-item
          v-for="(image, index) in images"
          :key="index"
          @click="goToUrl(image.link)"
        >
          <img v-lazy="image.pic_url" class="m-banner" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- <div class="bargain" @click="$router.push('/en/bargain')" v-if="true">
        <img src="@/assets/bargain/bargain_en.png" alt="">
      </div> -->
    <!-- 活动专区 -->
    <div style="width:100%;padding: 0 12px;margin-top:10px;" v-if="showActive">
      <index-active :data="activeList" :loading="aloading"></index-active>
    </div>
    <!-- 落地页 -->
    <div style="width:100%;padding: 0 12px;margin-top:10px;" v-if="landingShow">
      <index-landing :data="landingList" :loading="lloading"></index-landing>
    </div>

    <div class="box">
        <div class="banner-box" @click="$router.push('/first?lang=en')">
          <img src="@/assets/first/firste1.png" class="banner-img">
        </div>
        <div class="haggle-box" @click="$router.push('/en/haggleList')">
          <div class="haggle-title">
            <img src="@/assets/first/ttkj_en.png" alt="">
          </div>
          <div class="haggle-content">
            <van-swipe :autoplay="5000" :stop-propagation="false" :show-indicators="false">
              <van-swipe-item v-for="(item, index) in haggleList" :key="index" class="haggle-goods">
                <div class="haggle-goods-item" v-for="(it, i) in item" :key="i">
                  <img v-if="it.goods.image" :src="it.goods.image.remote_url" alt="">
                  <p>$<span>{{it.bargain_goods_sku[0].sales_price}}</span></p>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </div>
    <!-- 新品首发 -->
    <index-cell
      title="New Product"
      value="Show All"
      to="/en/newProduct"
      class="m-index-new"
    />
    <index-new
      style="padding-top: 0"
      :data="newProductList"
      :loading="loading"
    ></index-new>

    <!-- 新品预售 -->
    <index-cell
      title="New Pre-Sale"
      value="Show All"
      to="/en/presale?id=6"
      class="m-index-new"
    />
    <index-new
      style="padding-top: 0"
      :data="newPreSaleList"
      isPre="1"
      :loading="loading"
    ></index-new>

    <!-- 热销单品 -->
    <index-cell
      title="Hot Deals"
      value="Show All"
      to="/en/hotsale"
      class="m-index-margin"
    />
    <goods-columns
      :data="hotProductList"
      :columns="1"
      @cartClick="handleCart"
      :loading="loading"
    ></goods-columns>

    <!-- 优质商家 -->
    <!-- <index-cell title="Quality Merchant" value="Show All"  to="/en/merchant/list" style="margin:4px 0 12px" />
    <index-shops :data="shop"></index-shops> -->

    <!-- 品牌好货 -->
    <index-cell
      title="Good Brand"
      value="Show All Brand"
      to="/en/better?id=8"
      style="margin: 4px 0 12px"
    />
    <index-brand :data="shop"></index-brand>

    <!-- 为您推荐 -->
    <m-divider title="Recommended" style="margin: 0 auto 10px auto"></m-divider>
    <goods-columns
      @cartClick="handleCart"
      :data="remGoodsList"
      :loading="loading" 
    ></goods-columns>

    <!-- 备案号 -->
    <div class="beian">
      <a href="http://www.beian.miit.gov.cn">浙ICP备19031612号</a>
    </div>

    <van-loading
      v-show="loading"
      type="spinner"
      color="#ED2A24"
      size="6.4vw"
    ></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku
      title="Add to Cart"
      :show="skuModal"
      @close="(val) => (skuModal = val)"
      @success="handleCartSuccess"
      :goodsId="goodsId"
    ></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>
    <transition name="van-fade">
      <cart-flex v-show="start" />
    </transition>

    <!-- <footer-bar  /> -->
    <div class="loginBtn" @click="LoginRules">
      <img src="@/assets/firstRE.png" alt="" />
    </div>
    <!-- 夜市指引 -->
    <div class="fixed" v-if="fixedShow">
      <div class="guide" v-show="!richmondShow">
      </div>
      <div
        class="fixed_white"
        :class="richmondShow?'richmondShow':''"
        @click="$router.push('/nightmarketcoupons')"
      ></div>
      <div class="fixed_btn"  v-show="!richmondShow" @click="stepClick">
        <img src="@/assets/market/btn3.png" alt="" />
      </div>
    </div>
    <!-- 翻牌抽奖指引 -->
    <div class="fixed" v-if="lotteryShow">
      <div class="fixed_cart" @click="toCartClick">
        <img src="@/assets/lottery/enCart.png" alt="">
      </div>
      <div class="cartFixed">
          <img src="@/assets/lottery/cardFixed.png" alt="">
      </div>
      <div :class="giftJson.level==2?'fixedPrice':'fixedPrice2'">
          <img :src="giftJson.pic" alt="">
      </div>
        <div class="fixed_btn2" @click="lotteryClick">
        <img src="@/assets/lottery/Iknow-min.png" alt="" />
      </div>
    </div>
    <!-- 优惠券指引 -->
    <div class="fixed" v-if="couponShow">
      <div class="fixed_coupon" @click.prevent="couponClick">
        <img src="@/assets/coupon/coupon_en.png" alt="">
      </div>
      <div class="fixed_close" @click.prevent="closeClick">
        <img src="@/assets/coupon/off.png" alt="">
      </div>
    </div>
    <div class="eleven-active" v-if="elevenShow">
      <div class="eleven-coupon">
        <img src="@/assets/index/christmas_en.png" alt="">
      </div>
      <div class="eleven-close" @click="handleKnow">
        <img src="@/assets/coupon/off.png" alt="">
      </div>
    </div>
    <!-- 黑五优惠券 -->
    <div class="fixed" v-if="couponEShow">
      <div class="fixed_coupon">
        <div class="fixed_closeE" @click.prevent="closeEClick">
          <img src="@/assets/coupon/off.png" alt="">
        </div>
        <img src="@/assets/coupon/couponE_en.png" alt="" @click="showEClick">
      </div>
    </div>
    <!-- 年货节优惠券 -->
    <div class="fixed" v-if="newyearShow">
      <div class="fixed_coupon">
        <img src="@/assets/coupon/couponY_en.png" alt=""  @click.prevent="closeYClick">
        <div class="fixed_closeE">
          <img src="@/assets/coupon/off.png" alt=""  @click.prevent="closeYClick">
        </div>
      </div>
    </div>
    <!-- 五周节优惠券 -->
    <div class="fixed" v-if="fiveyearShow">
      <div class="fixed_coupon">
        <img src="@/assets/coupon/coupon5_en.png" alt=""  @click.prevent="close5Click">
        <div class="fixed_closeE">
          <img src="@/assets/coupon/off.png" alt=""  @click.prevent="close5Click">
        </div>
      </div>
    </div>
    <!-- 黑五优惠券 -->
    <div class="fixed" v-if="fridayShow">
      <div class="fixed_coupon">
        <img src="@/assets/eleven/blackcoupon_en.png" alt=""  @click.prevent="closeBClick">
        <div class="fixed_closeE">
          <img src="@/assets/eleven/button_en.png" alt=""  @click.prevent="closeBClick">
        </div>
      </div>
    </div>
    <!-- 圣诞节优惠券 -->
    <div class="fixed" v-if="christmasShow">
      <div class="fixed_coupon">
        <img src="@/assets/eleven/coupon_en.png" alt=""  @click.prevent="closeFClick">
      </div>
    </div>
    <let-it-snow
      v-bind="snowConf"
      :show="Snowshow"    
    ></let-it-snow>
    <!-- <m-scroll-top :show="scrollTop" :dom="$refs.scroll"></m-scroll-top> -->
  </section>
</template>

<script>
/* eslint-disable */
import IndexSearch from "@/components/en/index-search.vue";
import IndexGrid from "@/components/en/index-grid.vue";
import IndexCell from "@/components/en/index-cell.vue";
import IndexNew from "@/components/en/index-new.vue";
import GoodsColumns from "@/components/en/goods-columns.vue";
import IndexShops from "@/components/en/index-shops.vue";
import MDivider from "@/components/en/m-divider.vue";
import MSku from "@/components/en/m-sku.vue";
import MAnimate from "@/components/en/cart-animate.vue";
import CartFlex from "@/components/en/cart-flex.vue";
import MScrollTop from "@/components/zh/m-scroll-top.vue";
import IndexBrand from "@/components/en/index-brand.vue";
import IndexActive   from '@/components/en/index-active.vue'
import IndexLanding   from '@/components/en/index-landing.vue'

import {
  sectionList,
  homeNew,
  hotGoods,
  userRemGoodsList,
  getBanner,
  preSaleList,
  userAllRemGoodsList,
  activityTheme,
  landingPage
} from "@/api/en/index.js";
import { checkLogin } from '@/api/zh/detail.js'
import {   is_gift, balance_return } from "@/api/zh/index.js";
import { business } from "@/api/zh/index.js";
import { haggle_list } from '@/api/zh/haggle.js'
import MixinScroll from "@/untils/js/mixin-scroll.js";
export default {
  mixins: [MixinScroll],
  name: "Index",
  components: {
    IndexSearch,
    IndexGrid,
    IndexCell,
    IndexNew,
    GoodsColumns,
    IndexShops,
    MDivider,
    MSku,
    MAnimate,
    CartFlex,
    MScrollTop,
    IndexBrand,
    IndexActive,
    IndexLanding
  },
  data() {
    return {
      snowConf: {
          windPower : 1,  
          speed : 3,
          count : 12,
          size : 10,
          opacity : 1,
          images: [
            require('../../../assets/index/drop1.png'),
            require('../../../assets/index/drop2.png'),
            require('../../../assets/index/drop3.png'),
            require('../../../assets/index/drop4.png')
          ]
      },
      Snowshow      : false,
      lotteryType: 2,
      index: 0, // 当前轮播的index
      sectionEntry: [],
      images: [],
      skuModal: false, // 控制显示 选择sku加入购物车面板
      aloading: false,
      loading: false,
      islock: false, // 防止多次加载
      start: false, // 是否开启加入购物车动画
      start_dom: null, // 开启动画的起点DOM
      activeList: [],
      newProductList: [],
      newPreSaleList: [],
      hotProductList: [],
      remGoodsList: [],
      page: 1,
      apage: 1,
      remAll: false,
      goodsId: 0,
      shop: [],
      scrollTop: 0,
      fixedShow: false, // 指引图
      lotteryShow: false,// 抽奖指引
      giftJson: '',//翻牌抽奖礼品
      len: '',
      showActive: false, // 首页主题活动
      couponShow: false, // 优惠券指引
      couponEShow: false, // 黑五优惠券
      newyearShow: false, // 年货节优惠券
      fiveyearShow: false,  //五周年优惠券
      fridayShow: false, // 黑五优惠券
      christmasShow: false,  // 圣诞节优惠券
      badge:'',
      show: false,
      shows: false,
      landingShow   : false,       // 落地页
      landingList   : [],
      lloading      : false,
      elevenShow    : false,     // 圣诞活动弹窗显示
      richmondShow  : false,
      haggleList    : [], // 砍价列表
    };
  },

  created() {
    this.getSectionList();
    this.getHomeNew();
    this.getHotGoods();
    this.getUserRemGoodsList();
    this.getBannerList();
    this.getShops();
    this.getPreSaleList();
    this.getActivityTheme()
    this.getLandingList()
    this.getHaggle()
    window.localStorage.removeItem("coupon");
    if(localStorage.getItem('newyear')) {
      this.elevenShow = false
    }
  },
  activated() {
    if(localStorage.getItem("pay_titleF")) {
      this.housePoster();
    }
    // this.guide();
    this.lottery();
    this.coupon();
    this.couponB();
    // this.couponChris();
    // this.couponE();
    // this.couponNew();
    // this.couponNY();
    // this.couponFive();
    this.$store.dispatch("getCart");
    // 检测小程序参数
    let isMiniProgram =
      this.getQueryVariable("isminiprogram") ||
      localStorage.getItem("isMiniProgram");
    if (isMiniProgram && isMiniProgram == 1) {
      // 从小程序进入
      this.$store.state.isMiniProgram = true;
      localStorage.setItem("isMiniProgram", 1);
    }
    if(localStorage.getItem("token") != null || localStorage.getItem("token2") != null) {
      this.badge = ''
      this.couponShow = false
    }
    // this.Snowshow = true;
    // setTimeout(() => {
    //   this.Snowshow = false;
    // }, 3000);
  },
  methods: {
    housePoster() {
      balance_return({pay_title: localStorage.getItem("pay_titleF")}).then(res => {  
          if(res){
              localStorage.removeItem("pay_titleF")
          }
      })
    },
    // 关闭圣诞弹窗
    handleKnow() {
      this.elevenShow = false
      localStorage.setItem('newyear2', true)
      checkLogin().then(res => {
        if(res) {
          if(res.status == 1) {
            // this.$router.push('/en/login')
            localStorage.removeItem('token')
          }
        }
      })
    },
    // 砍价列表
    getHaggle() {
      haggle_list({page:1}).then(res => {
        if(res) {
          let len = 0
           if(res.data.data.length > 6) {
            len = 6
          } else {
            len = res.data.data.length
          }
          for(var i=0;i< len; i+=2) {
            this.haggleList.push(res.data.data.slice(i, i + 2))
          }
        }
      })
    },
    // 跳转砍价详情
    goHaggle(it) {
      if(it.bargain_user_goods_sku) {
        this.$router.push({path:`/en/haggleDetail/${it.goods_id}`,query:{haggle_id:it.bargain_user_goods_sku.id}})
      } else {
        this.$router.push({path:`/en/haggle/${it.goods_id}`,query:{id:it.id}})
      }
    },
    // 活动落地页
    getLandingList() {
      this.lloading = false
      landingPage().then(res => {
        if(res) {
          this.landingList = res.data
          this.landingShow = true
        } else {
          this.landingShow = false
        }
      })
    },
     // 获取主题活动
    getActivityTheme() {
      this.aloading = true
      activityTheme().then(res => {
        if(res){
          this.activeList = res.data
          this.activeList.list = []
          if(this.activeList.goods_list.length > 9) {
            this.len = 9
          } else {
            this.len = this.activeList.goods_list.length
          }
          for(var i=0;i< this.len; i+=3) {
            this.activeList.list.push(this.activeList.goods_list.slice(i, i + 3))
          }
          this.showActive = true
        } else {
          this.showActive = false
        }
      }).finally(() => {
        this.aloading = false
      })
    },
    //  go cart
    toCartClick() {
      this.lotteryShow = false
      this.$router.push('/en/cart');
      sessionStorage.setItem('lottery', true);
    },
    // 翻牌抽奖隐藏
    lotteryClick() {
      sessionStorage.setItem('lottery', true);
      this.lotteryShow = false
    },
    // 翻牌抽奖页面
    lottery() {
      if (!sessionStorage.getItem('lottery') && this.$route.query.lottery) {
        localStorage.setItem('token', this.$route.query.lottery);
        is_gift().then(res => {  
          if(res){
            this.giftJson = res.data;
            this.lotteryShow = true
          }
      })
      }else {
        this.lotteryShow = false
      }
    },
    // 夜市指引隐藏
    stepClick() {
      let isFirstz = window.sessionStorage.getItem("isFirstz");
      if (!isFirstz) {
        window.sessionStorage.setItem("isFirstz", true);
      }
      this.richmondShow = true;
      setTimeout(() => {
        this.fixedShow = false;
        this.richmondShow = false;
      }, 1000);
    },
    // 夜市指引判断
    guide() {
      if (this.$route.query.mooby_token) {
        localStorage.setItem("token", this.$route.query.mooby_token);
        localStorage.setItem("token2", this.$route.query.mooby_token);
      }
      let isFirst = window.sessionStorage.getItem("isFirstz");
      if (!isFirst && this.$route.query.guied == 1) {
        this.fixedShow = true;
      } else {
        this.fixedShow = false;
      }
    },
    // 优惠券
    coupon() {
      let isFirst = window.localStorage.getItem("coupon");
      if (!isFirst && this.$route.query.coupon == 1) {
        this.couponShow = true
      } else {
        this.couponShow = false
      }
      if(isFirst) {
        this.badge = '2'
      }
    },
    // 黑五优惠券
    couponE() {
      let isFirst = window.localStorage.getItem("couponE");
      if (!isFirst) {
        this.couponEShow = true
      } else {
        this.couponEShow = false
      }
    },
     // 黑五优惠券
     couponB() {
      let isFirst = window.localStorage.getItem("couponB");
      if (!isFirst) {
        this.fridayShow = true
      } else {
        this.fridayShow = false
      }
    },
    // 圣诞节优惠券 
    couponChris() {
      let isFirst = window.localStorage.getItem("couponChris");
      if (!isFirst) {
        this.christmasShow = true
      } else {
        this.christmasShow = false
      }
    },
    // 圣诞节优惠券
    couponNew() {
      let isFirst = window.localStorage.getItem("newyear2");
      if (!isFirst) {
        this.elevenShow = true
      } else {
        this.elevenShow = false
      }
    },
    // 年货节优惠券
    couponNY() {
      let isFirst = window.localStorage.getItem("couponY");
      if (!isFirst) {
        this.newyearShow = true
      } else {
        this.newyearShow = false
      }
    },
    //  五周节优惠券
    couponFive() {
      let isFirst = window.localStorage.getItem("coupon5");
      if (!isFirst) {
        this.fiveyearShow = true
      } else {
        this.fiveyearShow = false
      }
    },
    // 优惠券隐藏
    closeClick() {
      let isFirst = window.localStorage.getItem("coupon");
      if (!isFirst) {
        window.localStorage.setItem("coupon", true);
      } 
      this.couponShow = false
      this.show = true
      this.shows = true
      setTimeout(() => {
        this.badge = '2'
        this.show = false
      },800)
    },
    showEClick() {
      checkLogin().then(res => {
        if(res) {
          this.$router.push('/en/login')
          localStorage.removeItem('token')
          window.localStorage.setItem("couponE", true);
          this.couponEShow = false
        }
      })
    },
    // 优惠券隐藏
    closeEClick() {
      let isFirst = window.localStorage.getItem("couponE");
      if (!isFirst) {
        window.localStorage.setItem("couponE", true);
      } 
      this.couponEShow = false
      checkLogin().then(res => {
        if(res) {
          if(res.status == 1) {
            this.$router.push('/en/login')
            localStorage.removeItem('token')
          }
        }
      })
    },
    // 年货节优惠券隐藏
    closeYClick() {
      let isFirst = window.localStorage.getItem("couponY");
      if (!isFirst) {
        window.localStorage.setItem("couponY", true);
      } 
      this.newyearShow = false
    },
    // 五周年优惠券隐藏
    close5Click() {
      let isFirst = window.localStorage.getItem("coupon5");
      if (!isFirst) {
        window.localStorage.setItem("coupon5", true);
      } 
      this.fiveyearShow = false
    },
    // 圣诞优惠券隐藏
    closeFClick() {
      let isFirst = window.localStorage.getItem("couponChris");
      if (!isFirst) {
        window.localStorage.setItem("couponChris", true);
      } 
      this.christmasShow = false
    },
    // 黑五优惠券隐藏
    closeBClick() {
      let isFirst = window.localStorage.getItem("couponB");
      if (!isFirst) {
        window.localStorage.setItem("couponB", true);
      } 
      this.fridayShow = false
    },
    LoginRules() {
      this.$router.push("/first?lang=en");
    },
    getQueryVariable(variable) {
      let query = window.location.search.substring(1);
      let vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    // 内容滑动事件
    handleScroll(e) {
      this.scrollTop = e.target.scrollTop;
      if (
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <=
        50
      ) {
        if (!this.islock) {
          e.preventDefault();
          e.stopPropagation();
          this.loading = true;
          if (this.remAll) {
            this.apage++;
          } else {
            this.page++;
          }
          this.getUserRemGoodsList();
        }
        this.islock = true;
      }
    },
    // 商品列表购物车点击事件
    handleCart(goods, e) {
      this.goodsId = goods;
      this.skuModal = true;
      this.start_dom = e;
    },
    // sku 弹窗加入购物车成功回调
    handleCartSuccess() {
      this.skuModal = false;
      let timer = setTimeout(() => {
        this.start = true;
        clearTimeout(timer);
      }, 300);
    },
    // 加入购物车动画结束
    handleCartEnd() {
      this.start = false;
    },
    getSectionList() {
      // 获取独立板块列表
      sectionList().then((res) => {
        if (res) {
          this.sectionEntry = res.data;
        }
      });
    },
    getPreSaleList() {
      // 新品预售
      this.loading = true;
      preSaleList(1,{is_page:0})
        .then((res) => {
          this.newPreSaleList = res.data
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getHomeNew() {
      // 获取新品首发列表
      this.loading = true;
      homeNew({is_page: 0,per_page: 20}).then((res) => {
          this.newProductList = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getHotGoods() {
      // 获取热门单品列表
      this.loading = true;
      hotGoods({is_page: 0,per_page: 20})
        .then((res) => {
          this.hotProductList = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUserRemGoodsList() {
      // 获取自定义为你推荐
      this.loading = true;
      if (!this.remAll) {
        userRemGoodsList({ page: this.page,is_page: 1,per_page: 20 }).then((res) => {
          if (res) {
            this.remGoodsList = this.remGoodsList.concat(res.data.data);
            this.islock = false;
          } else {
            this.remAll = true;
            this.apage = 1;
            this.getUserRemGoodsList();
          }
        }).finally(() => {
          this.loading = false
        });
      } else {
        userAllRemGoodsList({ page: this.apage }).then((res) => {
          if (res) {
            this.islock = false;
            this.remGoodsList = this.remGoodsList.concat(res.data.data);
          }
        }).finally(() => {
          this.loading = false
        });
      }
    },
    getBannerList() {
      // 获取首页轮播图
      getBanner(10).then((res) => {
        if (res) {
          this.images = res.data;
        }
      });
    },
    goToUrl(url) {
      // 跳转链接
      if (url.indexOf("http") != -1) {
        window.location.href = url;
      } else {
        this.$router.push(url);
      }
    },

    getShops() {
      business({is_select: 1, is_page: 0 , per_page: 6}).then((res) => {
        if (res) {
          this.shop = res.data;
        } else {
          this.shop = [];
        }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path == "/nightmarketcoupons") {
        vm.$router.replace("/en");
      }
    });
  },
};
</script>

<style lang="less" scoped>
.fixed {
  .fixed_cart {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: fixed;
    top: 1px;
    right: 40px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .cartFixed {
    width: 260px;
    position: relative;
    left: 23%;
    top: 3%;
    img {
      width: 100%;
    }
  }
  .fixedPrice {
    width: 316.5px;
    height: 220px;
    margin: 8% auto;
    background-image: url("../../../assets/lottery/secondPrizeen.png");
    background-size: 100% 100%;
    img {
      height: 80%;
      display: block;
      margin: auto;
    }
  }
  .fixedPrice2 {
    width: 316.5px;
    height: 220px;
    margin: 8% auto;
    background-image: url("../../../assets/lottery/thirdPrizeen.png");
    background-size: 100% 100%;
    img {
      height: 80%;
      display: block;
      margin: auto;
    }
  }
  .fixed_btn2 {
    top: 3%;
    width: 220px;
    margin: auto;
    img {
      width: 90%;
      display: block;
      margin: auto;
    }
  }
  .fixed_coupon {
    position: fixed;
    width: 100%;
    height: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 45%;
    img {
      width: 100%;
      display: block;
      margin: auto;
    }
  }
  .fixed_close {
    position: fixed;
    width: 24px;
    height: 24px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40vw;
    img {
      width: 100%;
    }
  }
  .fixed_closeE {
    // position: fixed;
    position: relative;
    width: 165px;
    height: 48px;
    top: 0;
    // left: 90%;
    // top: -5vw;
    left: 50%;
    transform: translate(-50%);
    img {
      width: 100%;
    }
  }
}
.fade_gift {
  width: 20px;
  height: 20px;
  position: fixed;
  z-index: 9;
  left: 50%;
  transform: translate(-50%,0);
  bottom: 50vw;
  img {
    width: 100%;
    height: 100%;
  }
}
.trans {
  transform: translate(-41vw,-95vw);
  transition: transform 0.8s ease-in;  
}
@media screen and (min-width: 1280px) {
  .isPc .m-index {
    height: 100% !important;
    padding-bottom: 0 !important;
  }
}

@import "./style.less";
.loginBtn {
  position: fixed;
  right: 10px;
  bottom: 120px;
  z-index: 998;
  display: none;
  img {
    display: block;
    width: 75px;
    // height: 70px;
    animation: scale 0.8s infinite linear alternate;
  }
  @keyframes scale {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }
}
.beian {
  width: 100%;
  height: 50px;
  line-height: 50px;
  a {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 50px;
    font-size: 10px;
    color: #888;
    text-align: center;
  }
}
.box {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  margin-top: 12px;
  height: 128px;
  .banner-box {
    width: 172px;
    margin-right: 7px;
    .banner-img {
      width:100%;
      height:100%
    }
  }
  .haggle-box {
    width: 172px;
    height: 128px;
    padding: 6px 8px 2px;
    border-radius: 6px;
    background: url('../../../assets/first/haggle1-bg.png') no-repeat;
    background-size: 100% 100%;
    .haggle-title {
      height: 29px;
      width: 157px;
      margin-bottom: 3px;
      img {
        // height: 29px;
        width: 157px;
        object-fit: fill;
      }
    }
    .haggle-goods {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      
      .haggle-goods-item {
        width: 72px;
        text-align: center;
        img {
          width: 70px;
          height: 70px;
        }
        p {
          color: #EB5940;
          font-size: 12px;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
}
.bargain {
  width: 351px;
  height: 76px;
  margin:0 auto 12px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>